import React, { useEffect } from "react";
import SideBar from "../pages/Containers/SideBar";
import ArticleReading from "../pages/Components/ArticleReading";
import Footer from "../pages/Components/Footer";

const BlogPost = ({ data, pageContext }) => {
  const dummyIssue = {
    topic: "...",
    slug: "",
    highlightColor: "FFF",
    lowlightColor: "000"
  }
  const article = pageContext.article
  const issue = pageContext.articleIssue || dummyIssue
  
  useEffect(() => {
    if (typeof(document) !== "undefined"){
      document.querySelector("html").style.setProperty('background-color', `#${issue.lowlightColor}`)
      document.querySelector(".articleBody").style.setProperty('--scrollbar-background', `#${issue.highlightColor}`);
    }
  },[])

  return (
    <>
      <div className ="main" style={{
        backgroundColor: `#${issue.lowlightColor}`,
        color: `#${issue.highlightColor}`
      }}>
        <SideBar 
          topic = {issue.topic}
          slug = {issue.slug}
          highlightColor = {issue.highlightColor}
          lowlightColor = {issue.lowlightColor}
        />
        <ArticleReading
          currentArticleObject = {article}
          issue = {issue}
          highlightColor = {issue.highlightColor}
          lowlightColor = {issue.lowlightColor}
        />
      </div>
      <Footer
        highlightColor = {issue.highlightColor}
        lowlightColor = {issue.lowlightColor}
      />
    </>
  );
};

export default BlogPost;

export function Head ({data , pageContext}){
  const article = pageContext.article
  return (
    <>
       <meta charSet="utf-8" />
       {/* <link rel="icon" href="%PUBLIC_URL%/favicon.ico" /> */}
       <meta name="viewport" content="width=device-width, initial-scale=1" />
       <meta name="theme-color" content="#000000" />
       <meta name="description"
         content="Monthly magazine for long-form articles and podcasts on videogames"
       />
        <link rel="preconnect" href="https://fonts.gstatic.com" /> 
       <link href="https://fonts.googleapis.com/css2?family=Bitter&family=JetBrains+Mono:wght@400&display=swap" rel="stylesheet" />
       
       <title>{article.title}</title>
    </>
  )
}